<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link href="/" class="brand-logo">
        <ff-logo class="header-logo" />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
          v-if="showContentBox"
        >
          <b-navbar-nav class="nav ml-auto btn-locale">
            <locale />
          </b-navbar-nav>
          <b-card-title class="mb-1">
            {{ $t('Forgot password') }}? 🔒
          </b-card-title>
          <b-card-text class="mb-2">
            {{ $t('Modules.Auth.Label.Enter your email to reset your password') }}
          </b-card-text>

          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
              @submit.prevent="validationForm"
            >
              <b-form-group
                label="Email"
                label-for="forgot-password-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required|email"
                >
                  <b-form-input
                    id="forgot-password-email"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false:null"
                    name="forgot-password-email"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
              >
                {{ $t('Modules.User.Label.Send Email') }}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t('Back to login') }}
            </b-link>
          </p>
        </b-col>
        <message-box v-else :title="messageBox.title" :message="messageBox.body" :status="messageBox.status"></message-box>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import FfLogo from "@core/layouts/components/ff-logo.vue";
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton, BNavbarNav
} from 'bootstrap-vue'
import { required, email } from '@validations'
import store from '@/store/index'
import MessageBox from '@core/components/custom/messageBox.vue'
import Locale from "@core/layouts/components/app-navbar/components/Locale.vue";
import AuthRepository from "@/modules/auth/repository/authRepository";

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    BNavbarNav,
    FfLogo,
    Locale,
    MessageBox
  },
  data() {
    return {
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      email,
      messageBox: {
        title: '',
        body: '',
        status: true
      },
      showContentBox: true
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    validationForm() {
      const vm = this
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          AuthRepository.sendResetPasswordLink({
            email: this.userEmail
          }).then((response) => {
            vm.messageBox.title = vm.$i18n.t('Successful') + '!'
            vm.messageBox.body = response.data.data.message
            vm.showContentBox = false
          });
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
