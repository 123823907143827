<template>
  <b-col
    sm="8"
    md="6"
    lg="12"
    class="px-xl-0 mx-auto message-box"
  >
    <b-card
      text-variant="center"
      class="card"
    >
      <b-avatar
        :variant="statusBox[status].variant"
        size="70"
        class="shadow mb-2"
      >
        <feather-icon
          size="28"
          :icon="statusBox[status].icon"
        />
      </b-avatar>
      <h1 class="mb-1 mt-50 text-white">
        {{ title }}
      </h1>
      <b-card-text class="m-auto">
        {{ message }}
      </b-card-text>
      <p class="text-center mt-2">
        <b-link :to="{name:'auth-login'}">
          <feather-icon icon="ChevronLeftIcon" /> {{ $t('Back to login') }}
        </b-link>
      </p>
    </b-card>
  </b-col>
</template>

<script>

import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BCard, BAvatar
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BCardTitle,
    BCardText,
    BImg,
    BCard,
    BAvatar
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
    status: {
      type: Boolean,
      required: true,
    }
  },
  setup(props, context) {
    const statusBox = {
      true: {
        icon: 'CheckCircleIcon',
        variant: 'primary'
      },
      false: {
        icon: 'AlertCircleIcon',
        variant: 'danger'
      }
    }
    return {
      statusBox
    }
  }
}
</script>

<style lang="scss">
.message-box {
  .card {
    box-shadow: none;
  }
}
</style>
